<template>
  <div class="space-y-8">
    <div class="flex flex-col items-center space-y-1">
      <h2 class="text-2xl text-white">{{ info.title }}</h2>
      <span class="text-brand-gray">{{ info.intro }}</span>
      <ul class="flex space-x-2">
        <li v-for="slogan in info.slogan" :key="slogan">
          <span class="text-sm text-brand-text-blue">{{ slogan }}</span>
        </li>
      </ul>
    </div>

    <div>
      <ul class="flex flex-wrap -mx-2">
        <li v-for="course in info.courses" :key="course.name" class="w-1/2 lg:w-1/4 px-2 mb-12 group">
          <div class="relative pb-7/12 w-full rounded overflow-hidden bg-gray-300 transform transition-transform duration-150 ease-linear group-hover:-translate-y-3">
            <img v-if="course.thumbnailUrl" :src="course.thumbnailUrl" alt="" class="absolute w-full h-full object-cover">
          </div>
          <div class="mt-4 text-sm">
            <h2 class="text-white">{{ course.name }}</h2>
            <span class="text-brand-gray">{{ course.slogan }}</span>
            <p class="text-brand-text-blue">{{ course.intro }}</p>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    info: {
      type: Object,
      default: () => {}
    }
  }
}
</script>
