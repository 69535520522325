<template>
  <div class="space-y-12 lg:space-y-16 overflow-hidden bg-brand-blue">
    <Video />

    <div class="space-y-12 lg:space-y-16">
      <div>
        <Introduction />
      </div>

      <div class="max-w-7xl mx-auto px-4 md:px-8">
        <div class="space-y-12 lg:space-y-16">
          <Block :info="operationSystemInfo" />

          <Block :info="businessMarketing" />

          <Block :info="skillsTraining" />
        </div>

        <Footer class="pt-12 pb-6 lg:pt-24 lg:pb-10" />
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs } from '@vue/reactivity'

import Block from '@/components/Block.vue'
import Footer from '@/components/Footer'
import Introduction from '@/components/Introduction.vue'
import Video from '@/components/Video.vue'

export default {
  name: 'App',

  components: {
    Block,
    Footer,
    Introduction,
    Video,
  },

  setup () {
    const state = reactive({
      operationSystemInfo: {
        title: '營運系統',
        intro: '功能、工具型產品',
        slogan: [
          '協助企業精簡營運流程',
          '減省成本',
          '業績數據化',
        ],
        courses: [
          {
            thumbnailUrl: '/images/operationSystemInfo/1.jpg',
            name: '報名系統',
            slogan: '課程報名、活動登記',
            intro: '適用行業：教育、會展、商會、政府部門、比賽、體育盛事、講座',
          },
          {
            thumbnailUrl: '/images/operationSystemInfo/2.jpg',
            name: '預約系統',
            slogan: '服務預約',
            intro: '適用行業：醫療、美容、美髮、清潔、場地租借、專業顧問、法律、銀行、政府部門',
          },
          {
            thumbnailUrl: '/images/operationSystemInfo/3.jpg',
            name: '會員系統',
            slogan: '會員管理、積分系統、提升顧客忠誠度',
            intro: '適用行業：餐飲、零售、服務業、健身室、酒店、學院',
          },
          {
            thumbnailUrl: '/images/operationSystemInfo/4.jpg',
            name: '庫存系統',
            slogan: '庫存管理、貨源調配、保質限期',
            intro: '適用行業：餐飲、銷售、醫療、銀行、政府部門',
          },
          {
            thumbnailUrl: '/images/operationSystemInfo/5.jpg',
            name: '商城系統',
            slogan: '電子商務、電子支付',
            intro: '適用行業：超市、銷售',
          },
          {
            thumbnailUrl: '/images/operationSystemInfo/6.jpg',
            name: '點餐系統',
            slogan: '堂食 / 外賣點餐、結帳',
            intro: '適用行業：飲食',
          },
          {
            thumbnailUrl: '/images/operationSystemInfo/7.jpg',
            name: '配送系統',
            slogan: '外賣、送貨、物流、打車',
            intro: '適用行業：餐飲外送、物流快遞、零售、幼兒接送、老人接送、寵物接送、出租車',
          },
          {
            thumbnailUrl: '/images/operationSystemInfo/8.jpg',
            name: '電郵發送系統',
            slogan: '企業',
            intro: '適用行業：政府部門、公共服務、銀行、酒店、學院',
          },
        ]
      },
      businessMarketing: {
        title: '企業營銷',
        intro: '公司形象、市場營銷型產品',
        slogan: [
          '優化企業面向公眾的形象',
          '讓市場了解企業的近況',
        ],
        courses: [
          {
            thumbnailUrl: '/images/businessMarketing/1.jpg',
            name: '公司形象',
            slogan: '',
            intro: '官方網頁、手機應用程式',
          },
          {
            thumbnailUrl: '/images/businessMarketing/2.jpg',
            name: '市場營銷',
            slogan: '',
            intro: '公司新聞、新品推薦',
          },
          {
            thumbnailUrl: '/images/businessMarketing/3.jpg',
            name: '即時推廣',
            slogan: '',
            intro: '會議展覽、短期活動',
          },
          {
            thumbnailUrl: '/images/businessMarketing/4.jpg',
            name: '數據演示',
            slogan: '',
            intro: '年度報告、圖文包',
          }
        ]
      },
      skillsTraining: {
        title: '技能培訓',
        intro: '過百教案經驗業界開發者導師',
        slogan: [
          '為企業資訊科技部門提供培訓',
          '線上線下學生逾五萬人',
        ],
        courses: [
          {
            thumbnailUrl: '/images/skillsTraining/1.jpg',
            name: 'HTML + CSS',
            slogan: '',
            intro: '基礎網頁開發',
          },
          {
            thumbnailUrl: '/images/skillsTraining/2.jpg',
            name: 'JAMStack',
            slogan: '',
            intro: '構建高可用高安全網站',
          },
          {
            thumbnailUrl: '/images/skillsTraining/3.jpg',
            name: 'Docker',
            slogan: '',
            intro: '入門及伺服器部署',
          },
          {
            thumbnailUrl: '/images/skillsTraining/4.jpg',
            name: 'IPhone',
            slogan: '',
            intro: '手機應用程式開發',
          }
        ]
      },
    })

    return {
      ...toRefs(state)
    }
  }
}
</script>
