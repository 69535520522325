<template>
  <div class="w-full flex flex-col-reverse items-center lg:flex-row">
    <div class="flex items-end mt-8 lg:mt-0">
      <div class="max-w-xs lg:max-w-sm">
        <img src="/images/logo.png" alt="" />
      </div>
    </div>

    <div class="flex-1">
      <div class="flex flex-col items-center lg:items-end lg:justify-end text-white text-center lg:text-right text-sm md:text-banse">
        <div>
          <span>contact us: </span>
          <a :href="`mailto:${ websiteInfo.email}`">{{ websiteInfo.email }}</a>
        </div>
        <span class="text-brand-gray">{{ websiteInfo.address }}</span>
        <span class="text-brand-text-blue">&copy; {{ (new Date()).getFullYear() }}, Azure Ocean Innovation and Technologies Limited. All rights reserved.</span>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
export default {
  setup () {
    const websiteInfo = ref({
      email: 'info@azureoceantech.com',
      address: 'Rua Um do Bairro da Concórdia No.57 - 77, Edf. Industrial Wan Tai, 7° Andar A1, Macau'
    })

    return {
      websiteInfo,
    }
  }
}
</script>
