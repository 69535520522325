<template>
  <div class="relative">
    <div class="relative w-screen h-screen ">
      <video ref="myVideo" autoplay loop muted playsinline class="absolute w-full h-full object-cover">
        <source src="/videos/video1.mp4" type="video/mp4">
      </video>

      <div class="absolute left-0 top-1/2 w-full transform -translate-y-1/2">
        <div class="h-20 md:h-16 relative max-w-4xl mx-auto">
          <ul>
            <li v-for="word in loopWords" :key="word" class="text-center text-3xl sm:text-4xl md:text-5xl lg:text-6xl text-white">
              <div class="absolute left-0 w-full transform transition duration-150 ease-linear" :class="[ word.isShow ? 'opacity-100' : 'opacity-0' ]">
                <ul class="md:flex md:justify-center">
                  <li v-for="slug in word.slugs" :key="slug">
                    {{ slug }}
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div class="w-full absolute bottom-10 sm:bottom-6 left-1/2 transform -translate-x-1/2">
        <div class="w-3/4 sm:max-w-sm mx-auto">
          <img src="/images/logo.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { nextTick, onMounted, ref } from 'vue'

export default {
  setup () {
    const myVideo = ref(null)
    const loopWords = ref([
      {
        slugs: ['資訊科技縮短了', '世界的距離'],
        isShow: false,
      },
      {
        slugs: ['推動社會前進', '和人類文明'],
        isShow: false,
      },
    ])

    onMounted(() => {
      nextTick(() => {
        myVideo.value.play()

        myVideo.value.addEventListener('timeupdate', e => {
          let videoCurrentTime = parseInt(e.target.currentTime)

          if (videoCurrentTime === 0) {
            loopWords.value[0].isShow = true
          }
          if (videoCurrentTime !== 0 && videoCurrentTime % 3 === 0) {
            if (videoCurrentTime % 2 === 1) {
              loopWords.value[videoCurrentTime % 2].isShow = true
              loopWords.value[(videoCurrentTime % 2) - 1].isShow = false
            } else {
              loopWords.value[videoCurrentTime % 2].isShow = true
              loopWords.value[(videoCurrentTime % 2) + 1].isShow = false
            }
          }
        })
      })
    })

    return {
      myVideo,
      loopWords
    }
  }
}
</script>
