<template>
  <div class="relative">
    <div class="max-w-7xl mx-auto px-2 relative z-10">
      <div class="flex items-center flex-col md:flex-row z-10">
        <div
          class="w-full md:w-1/2 hidden md:block"
          data-aos="fade-down"
          data-aos-offset="-100"
          data-aos-delay="50"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
          data-aos-mirror="true"
          data-aos-once="false"
          data-aos-anchor-placement="top-center"
        >
          <div class="relative pb-11/12">
            <img src="/images/computer.png" alt="" class="absolute w-full h-full" />
          </div>
        </div>
        <div
          class="w-full md:w-1/2 md:hidden"
          data-aos="fade-left"
          data-aos-offset="120"
          data-aos-delay="0"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
          data-aos-mirror="true"
          data-aos-once="false"
          data-aos-anchor-placement="top-center"
        >
          <div class="relative pb-11/12">
            <img src="/images/computer.png" alt="" class="absolute w-full h-full" />
          </div>
        </div>

        <div
          class="w-full md:w-1/2 h-72 hidden md:flex items-center justify-center lg:items-end lg:pl-12"
          data-aos="fade-up"
          data-aos-offset="-100"
          data-aos-delay="0"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
          data-aos-mirror="true"
          data-aos-once="false"
          data-aos-anchor-placement="top-center"
        >
          <div class="md:text-base lg:text-xl tracking-widest text-white">
            <p v-for="(firstParagraph, i) in firstParagraphs" :key="firstParagraph" :class="{ 'mb-6': i + 1 === firstParagraphs.length }">{{ firstParagraph }}</p>
            <p v-for="secondParagraph in secondParagraphs" :key="secondParagraph">{{ secondParagraph }}</p>
          </div>
        </div>
        <div
          class="w-full md:w-1/2 h-72 md:hidden flex items-center justify-center lg:items-end lg:pl-12"
          data-aos="fade-right"
          data-aos-offset="-100"
          data-aos-delay="0"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
          data-aos-mirror="true"
          data-aos-once="false"
          data-aos-anchor-placement="top-center"
        >
          <div class="md:text-base lg:text-xl tracking-widest text-white">
            <p v-for="(firstParagraph, i) in firstParagraphs" :key="firstParagraph" :class="{ 'mb-6': i + 1 === firstParagraphs.length }">{{ firstParagraph }}</p>
            <p v-for="secondParagraph in secondParagraphs" :key="secondParagraph">{{ secondParagraph }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="absolute top-1/2 left-0 right-0 z-0 transform md:-translate-y-16 lg:-translate-y-24">
      <div class="md:h-44 lg:h-72 bg-gradient-to-r from-brand-blue-light via-brand-blue to-brand-blue-dark"></div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from 'vue'
import AOS from 'aos';
// import 'aos/dist/aos.css';

export default {
  setup () {
    const firstParagraphs = ref([
      '科技的發展才使人類有著',
      '嶄新又美好的生活',
      '我們提供創新的解決方案',
      '讓科技智能簡化工序',
      '提昇營運及管理效率'
    ])
    const secondParagraphs = ref([
      '天藍海創新科技有限公司',
      '你的資訊科技專家',
    ])

    onMounted(() => {
      AOS.init({
        once: false
      })
      console.log(AOS)
    })

    return {
      firstParagraphs,
      secondParagraphs
    }
  }
}
</script>
